import React, {useState, useEffect} from 'react';
import * as PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import {ProductImage} from '@partssourceinc/react-ui-core';
import MediaQuery from 'react-responsive';

const Box = styled.div`
    position: relative;
    margin-top: 15px !important;
    padding: 15px;
    border-radius: 6px;
    border: 2px solid #CCCCCC;
    cursor: pointer;
    width: 200px;
    background: #fff;

    ${props => props.completed && css`
        border: 2px solid #17AB78;
    `}

    ${props => props.active && css`
        border: 2px solid #FF9505;
    `}

    @media (min-width: 820px) {
        height: 90%;
    }
`;

const Title = styled.div`
    display: inline-block;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 3px;
    text-align: left;
`;

const Description = styled.div`
    font-weight: 400;
    font-size: 14px;
    margin-top: 5px;
    display: inline-block;
    width: 100%;
    color: #000000;
    font-family: "Source Sans Pro", sans-serif;
    line-height: 22px;
    text-align: left;
`;

const Step = styled.span`
    margin-top: -10px;
    border: 2px solid black;
    border-radius: 50%;
    font-size: 16px;
    width: 22px;
    height: 22px;
    display: inline-block;
    padding-left: 5px;
    font-weight: 600;
    padding-bottom: 20px;
    margin-right: -3px;
`;

const CheckedIcon = styled.span`
    background: url('/images/icn_checkmark.png');
    background-size: 22px;
    vertical-align: middle;
    position:relative;
    top: -3px;
    height: 22px;
    width: 22px;
    display: block;
`;

const PencilIcon = styled(FontAwesomeIcon)`
    border: 2px solid black;
    border-radius: 50%;
    margin-top: -10px;
    height: 21px;
    padding-left: 3px;
    padding-right: 3px;
`;

const StepBox = (props) => {
    const {title, description, active, index, product, completed, valid, fields, hasRequiredFields, ...rest} = props;
    const [stepFields, setStepFields] = useState();
    const [stepCompleted, setStepCompleted] = useState();
    const [stepValid, setStepValid] = useState();

    useEffect(() => {
        setStepFields(fields);
    }, [fields]);

    useEffect(() => {
        setStepCompleted(completed);
    }, [completed]);

    useEffect(() => {
        setStepValid(valid);
    }, [valid]);

    return (
        <Box
            completed={stepCompleted && stepValid}
            active={!(stepCompleted && stepValid) && active}
            {...rest}
        >
            <Title>
                <div className="row" style={{height: '23px'}}>
                    <div className="col-xs-3 col-md-2 align-middle">
                        {
                            active ?
                                <PencilIcon icon={faPencilAlt} size="lg" /> :
                                stepCompleted && stepValid ?
                                    <CheckedIcon /> :
                                    <Step>{hasRequiredFields ? index : (index + 1)}</Step>
                        }
                    </div>
                    <div className="col-xs-9 col-md-10 align-middle">
                        {title}
                    </div>
                </div>
            </Title>
            <MediaQuery minWidth={820}>
                <Description>
                    <div className="row" style={{height: '23px'}}>
                        <div className="col-md-2 align-middle">
                            {!hasRequiredFields || (hasRequiredFields && index !== 0) ? <ProductImage
                                url={
                                    product.images && product.images.length > 0 ?
                                        product.images[0].thumbnail
                                        : null
                                }
                                width={50} /> : null}
                        </div>
                        {/* <div className="col-md-10 align-middle">
                            {(!stepCompleted || active) ?
                                description :
                                stepFields ? stepFields.map(f => {
                                    return (<div key={`${f.fieldUid}_${index}`}>{f.prompt}: <span>{f.value}</span></div>)
                                }) : null
                            }
                        </div> */}
                    </div>
                </Description>
            </MediaQuery>
        </Box>
    );
};

StepBox.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    product: PropTypes.shape({
        images: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    completed: PropTypes.bool.isRequired,
    valid: PropTypes.bool.isRequired,
    fields: PropTypes.arrayOf(PropTypes.object).isRequired,
    hasRequiredFields: PropTypes.bool.isRequired,
};

export default StepBox;
