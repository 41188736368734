import React, {useState, useRef, useEffect} from 'react';
import Slider from 'react-slick';
import * as PropTypes from 'prop-types';
import StepBox from './StepBox';

import './steps-carousel.less'

const StepsCarousel = (props) => {
    const {steps, product, currentStep, hasRequiredFields, onSetSelectedStep} = props;
    const [selectedStep, setSelectedStep] = useState(currentStep);
    const [WizardSteps, setWizardSteps] = useState([]);
    const [showArrows, setShowArrows] = useState(false);

    const sliderRef = useRef();

    useEffect(() => {
        setSelectedStep(currentStep);
        if (currentStep !== 0 && sliderRef.current.props.arrows === false)
            setShowArrows(true);

        if (sliderRef && sliderRef.current && sliderRef.current.slickGoTo)
            sliderRef.current.slickGoTo(currentStep);
    }, [currentStep]);

    useEffect(() => {
        if (steps) {
            setWizardSteps(steps);
        }
    }, [steps]);

    const onSelectedStepLocal = (event, index) => {
        if ((index < currentStep) || (steps[currentStep].valid && steps[currentStep].completed && steps[index - 1].valid && steps[index - 1].completed)) {
            // Allow navigate
            if (onSetSelectedStep)
                onSetSelectedStep(index);
        } else {
            // Prevent navigation
            let target = event.target.closest('.slick-slide') || event.target;
            target.blur();
        }
    };
    
    return (
        <div className="steps-carousel">
            <Slider
                ref={sliderRef}
                arrows={showArrows}
                autoplay={false}
                dots={false}
                initialSlide={0}
                centerMode={true}
                focusOnSelect={false}
                variableWidth={true}
                infinite={false}
                responsive={[
                    {
                        breakpoint: 820,
                        settings: {
                            arrows: false,
                            slidesToShow: 1,
                            centerMode: false,
                            slidesToScroll: 1,
                        },
                    },
                ]}
            >
                { 
                    WizardSteps ? WizardSteps.map((s ,i) => (
                        <StepBox 
                            className={i === steps.length - 1 ? 'last' : 'not-last'} 
                            key={i} 
                            index={i} 
                            title={s.title} 
                            description={s.description} 
                            product={product}
                            fields={WizardSteps[i].fields}
                            completed={WizardSteps[i].completed}
                            valid={WizardSteps[i].valid}
                            onClick={(e) => onSelectedStepLocal(e, i)} 
                            active={i === selectedStep}
                            hasRequiredFields={hasRequiredFields} />
                    )) : null
                }            
            </Slider>
        </div>
    );
};

StepsCarousel.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.object).isRequired, 
    onSelectedStep: PropTypes.func,
    product: PropTypes.shape({
        images: PropTypes.arrayOf(PropTypes.object),
    }).isRequired, 
    currentStep: PropTypes.number.isRequired,
    hasRequiredFields: PropTypes.bool.isRequired,
};

export default StepsCarousel;
