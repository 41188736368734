import React, {useState, useEffect} from 'react';
import * as PropTypes from 'prop-types';
import {CSSTransition} from 'react-transition-group';
import styled from 'styled-components';
import {TextField} from '@partssourceinc/react-ui-core';

import './../../less/repairWizard.less'

const RequiredFieldsContainer = styled.div`
    position: absolute;
    left: calc(50% - (280px / 2));
    margin: auto;
    max-width: 280px;
    margin-top: 20px;
    text-align: center;
    @media (min-width: 820px) {
        width: 50%;
    }
`;

const Title = styled.h3`
    font-weight: 600;
`;

const SectionContainer = styled.div`
    width: 280px;
    max-width: 100%
`;

const RequiredProperties = ({autoFocus, onFieldUpdate, step, myIndex, currentStepIndex, onValidateCurrentStep, focusOnContinue}) => {
    const [showErrors, setShowErrors] = useState(false);
    const [errors, setErrors] = useState({});
    const [fields, setFields] = useState([]);
    const [dirtyFields, setDirtyFields] = useState([]);
    const [invalidFields, setInvalidFields] = useState([]);
    const [show, setShow] = useState(false);
    const [enableMoveNext, setEnableMoveNext] = useState(false);

    useEffect(() => {
        setEnableMoveNext(false);
        setShow(myIndex === currentStepIndex);
        setTimeout(() => {
            setEnableMoveNext(true);
        }, 500);
    }, [currentStepIndex]);

    useEffect(() => {
        if (step && step.fields) {
            setFields(step.fields);
            setDirtyFields([]);
            let completed = checkCompleted(step.fields);
            let valid = false;
            if (completed)
                valid = validateForm(step.fields, false, []);
            else
                valid = validateForm(step.fields, true, []);

            if (onValidateCurrentStep)
                onValidateCurrentStep(valid, completed, myIndex);
        }
    }, [step]);

    const updateState = (event, data) => {
        let arr = fields.slice(); // copy the array
        arr.filter(x => x.fieldUid === data.id)[0].value = data.value;
        setFields(arr);

        let dirtyArr = dirtyFields.slice();
        if (!dirtyArr.find(d => d === data.id)) {
            dirtyArr.push(data.id);
        }
        setDirtyFields(dirtyArr);

        let valid = validateForm(arr, true, dirtyArr);
        let completed = checkCompleted(arr);
        if (onValidateCurrentStep)
            onValidateCurrentStep(valid, completed, myIndex);

        if (onFieldUpdate) 
            onFieldUpdate(arr);
    }

    const validateForm = (flds, onlyDirty, dirtyArr) => {
        let errs = {};
        let invalidFields = {};
        if (flds && flds.length) {
            for (let i = 0; i < flds.length; i++) {
                if (flds[i].isRequired && 
                    ((!onlyDirty && (!flds[i].value || flds[i].value === '')) || (onlyDirty && dirtyArr.find(d => d === flds[i].fieldUid) && (!flds[i].value || flds[i].value === '')))
                ) {
                    errs[flds[i].fieldUid] = `${flds[i].prompt} is required`;
                }
            }
            invalidFields = (flds || []).filter(x => x.value && x.formatRegex && !new RegExp(`^${x.formatRegex}$`, '').test(x.value));
            let anyError = flds.some(f => f.isRequired && (!f.value || f.value === '')) || invalidFields.length > 0; 
            setErrors(errs);
            setInvalidFields(invalidFields);
            setShowErrors(anyError);
            return !anyError;
        } else {
            setErrors(errs);
            setShowErrors(false);
            return true;
        }
    }

    const checkCompleted = (flds) => {
        // Check that all required fields have values
        if (flds && flds.length) {
            return !flds.some(f => f.isRequired && !f.value);
        } else {
            return true;
        }
    }

    const moveToNext = (event, i) => {
        if (event.keyCode === 13 && enableMoveNext) {
            const nextField = fields[i].fieldUid
            const nextSibling = document.querySelector(
                `input[id='${nextField}']`
            );
            // If found, focus the next field
            if (nextSibling !== null) {
                nextSibling.focus();
            }
            event.stopPropagation();
        }
    };

    const moveToContinue = (event) => {
        if (event.keyCode === 13) {
            if (focusOnContinue) {
                focusOnContinue();
            }
            event.stopPropagation();
        }
    };
    
    const renderField = (f, i) => {
        const showRequiredFieldWarning = showErrors && f.isRequired && (!f.value || f.value === '') && errors[f.fieldUid];
        const showInvalidFieldWarning = (showErrors && invalidFields.length>0 && invalidFields.find(i =>i.fieldUid  === f.fieldUid));
        const _maxLength =
            f.fieldUid === '11111111-1111-1111-1111-111111111111' ||
            f.fieldUid === '22222222-2222-2222-2222-222222222222' ||
            f.fieldUid === '33333333-3333-3333-3333-333333333333' ||
            f.fieldUid === '44444444-4444-4444-4444-444444444444' ? 32 : 150;
  
            let formattedField = fields && fields.find(a=>a.fieldUid===f.fieldUid && a.formatRegex!=='') ? 
            fields.filter(u => u.fieldUid===f.fieldUid && u.formatRegex!=='')
            : null;
        
              if (formattedField)
              {
                f.placeholder = formattedField[0].placeholder;
                f.formatRegex = formattedField[0].formatRegex;
                f.errorMsg = formattedField[0].errorMsg;
              }
              else
              {
                f.placeholder = f.placeholder || 'Enter '+f.prompt;
                f.formatRegex = f.formatRegex || '';
                f.errorMsg = errors[f.fieldUid] || f.errorMsg;
              }
        return !f.fieldType || f.fieldType === 1 ? 
            <TextField
                className="txbFields"
                text={f.value}
                autoFocus = {i === 0 && autoFocus }
                id={`${f.fieldUid}`}
                key={`${f.fieldUid}_${i}`}
                tabIndex={0}
                label={f.prompt}
                placeholder={f.placeholder}
                pattern = {f.formatRegex}
                errorMessage={f.errorMsg}
                maxLength={_maxLength}
                style={{textAlign: 'left'}}
                showErrorMessage={showRequiredFieldWarning || showInvalidFieldWarning}
                onChange={(e, d) => updateState(e, d)}
                onKeyUp={(e) => (i === fields.length - 1) ? moveToContinue(e) : moveToNext(e, i + 1)}
            />
            : 
            <TextField
                className="txbFields"
                text={f.value}
                autoFocus = {i === 0 && autoFocus }
                id={`${f.fieldUid}`}
                key={`${f.fieldUid}_${i}`}
                multiLine={true}
                tabIndex={0}
                maxLength={32}
                label={f.prompt}
                placeholder={`Enter ${f.prompt}`}
                errorMessage={errors[f.fieldUid]}
                style={{textAlign: 'left'}}
                showErrorMessage={showRequiredFieldWarning}
                onChange={(e, d) => updateState(e, d)}
                onKeyUp={(e) => (i === fields.length - 1) ? moveToContinue(e) : moveToNext(e, i + 1)}
            />;
    };

    const renderSection = () => {
        const renderFields = fields ? fields.map((f, i) =>
            renderField(f, i)
        ) : null;
  
        return (
            <SectionContainer>
                {renderFields}
            </SectionContainer>
        );
    };

    return (
        <CSSTransition
            in={show}
            timeout={500}
            classNames="required-display"
            unmountOnExit={true}
        >
            <RequiredFieldsContainer>
                <Title>Required Information</Title>
                {renderSection()}
            </RequiredFieldsContainer>
        </CSSTransition> 
    );
};

RequiredProperties.propTypes = {
    autoFocus: PropTypes.bool, 
    onFieldUpdate: PropTypes.func,
    step: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        fields: PropTypes.arrayOf(PropTypes.object).isRequired,
        isUrgency: PropTypes.bool,
        repairReason: PropTypes.string,
        repairReasonExtraInfo: PropTypes.string,
        completed: PropTypes.bool.isRequired,
        valid: PropTypes.bool.isRequired,
    }).isRequired, 
    myIndex: PropTypes.number.isRequired, 
    currentStepIndex: PropTypes.number.isRequired, 
    onValidateCurrentStep: PropTypes.func,
    focusOnContinue: PropTypes.func,
};

export default RequiredProperties;
