import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import axios from 'axios';
import styled, {css} from 'styled-components';
import MediaQuery from 'react-responsive';
import StepsCarousel from '../components/RepairWizard/StepsCarousel';
import RequiredProperties from '../components/RepairWizard/RequiredProperties';
import WizardStep from '../components/RepairWizard/WizardStep';
import PopupAdmin from '../components/PopupAdmin';
import AddToCartConfirmation from '../components/AddToCartConfirmation';
import {getCookie} from 'utility';
import {getCompanyAndVendorFields, saveAssetInformationToStorage, getPartCondition, getPurchaseChoice} from 'productUtility';
import {Button, theme, brandFont} from '@partssourceinc/react-ui-core';
import {actionCreators as cartActions} from 'stores/Cart'

import './../less/repairWizard.less'

const Title = styled.h1`
    font-weight: 300 !important;
`;

const RepairWizardContainer = styled.div`
    width: 100%;
`;

const RepairWizardContainerRow = styled.div`
    text-align: center;
    width: 100%;
`;

const RepairWizardContainerCol = styled.div`
`;

const RepairWizardFieldsContainerCol = styled.div`
    position: relative; 
    min-height: 120px;
    ${props => props.isRequiredRow && props.rows && css`
        height: calc(71px + (${props.rows} * 62px))
    `}
    ${props => !props.isRequiredRow && props.rows && css`
        height: calc(105px + (${props.rows} * 62px))
    `}
`;

const OrRow = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    position: relative;
    z-index: 1;

    &:before {
        border-top: 2px solid #dfdfdf;
        content:"";
        margin: 0 auto;
        position: absolute;
        top: 50%; left: 0; right: 0; bottom: 0;
        width: 60%;
        z-index: -1;
    }

    span { 
        background: #fff; 
        padding: 0 15px; 
    }
`;

const CancelLinkRow = styled.div`
    text-align: center;
`;

const Footer = styled.div`
    width: 100%;
    margin-top: 24px;
    padding: 20px 0px 20px 0px;
    border-top: 1px solid ${theme.grey3};
    position: relative;
    bottom: 0px;

    @media (min-width: ${theme.screenMDMin}) {
        width: 50%;
        margin: auto;
        max-width: 600px;
        margin-top: 24px;
        padding: 16px 0px 16px 0px;
        border-top: 1px solid ${theme.grey3};
        text-align: right;
    }

    >:not(:first-child) {
        margin-left: .25rem;
    }

    >:not(:last-child) {
        margin-right: .25rem;
    }
`;

const CancelLink = styled.a`
    ${brandFont('14px')};
    color: ${theme.grey3};
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    text-transform: uppercase;
    outline: none;

    @media (min-width: ${theme.screenMDMin}) {
        margin-right: 20px !important;
    }

    &.disabled {
        opacity: .4;
        pointer-events: none;
    }
    
    &:hover {
        color: $"{theme.grey3};
        text-decoration: underline;
        outline: none;
    }

    &:focus {
        color: ${theme.grey3};
        text-decoration: underline;
        outline: none;
    }

    &:active:focus {
        color: ${theme.grey3};
        text-decoration: underline;
        outline: none;
    }
`;

const RepairWizard = (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const quantity = location && location.state && location.state.quantity ? location.state.quantity : 0;
    const product = location && location.state && location.state.product ? location.state.product : null;
    const selectedOption = location && location.state && location.state.option ? location.state.option : null;
    const isQuote = location && location.state && location.state.isQuote ? location.state.isQuote : false;
    const hasLoanerAdded = location && location.state && location.state.hasLoanerAdded ? location.state.hasLoanerAdded : false;
    const user = useSelector(state => state.user);
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [validCurrentStep, setValidCurrentStep] = useState(false);
    const [steps, setSteps] = useState([]);
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const [hasRequiredFields, setHasRequiredFields] = useState(false);
    const [saving, setSaving] = useState(false);
    const [showAddToCartConfirmation, setShowAddToCartConfirmation] = useState(false);
    const {loadUserCart} = cartActions;

    useEffect(() => {
        if (!product || !selectedOption || !quantity) {
            history.push('/');
            return;
        }
        let selectedFacilityId = (user.facility && user.facility.facilityId) ? user.facility.facilityId : 0;
        let cf = getCompanyAndVendorFields(false, false, selectedOption, selectedFacilityId); 
        let reqFields = cf ? cf.filter(c => c.isRequired && c.fieldUid !== '44444444-4444-4444-4444-444444444444' && c.fieldUid !== '77777777-7777-7777-7777-777777777777') : [];
        let itemFields = cf ? cf.filter(c => c.fieldUid === '44444444-4444-4444-4444-444444444444' || c.fieldUid === '77777777-7777-7777-7777-777777777777') : [];
        let st = [];
        if ((itemFields.filter(c => c.fieldUid === '77777777-7777-7777-7777-777777777777').length === 0)) {
            itemFields.push({
                fieldUid: '77777777-7777-7777-7777-777777777777',
                isDefault: true,
                isRequired: true,
                lineItemId: 0,
                orderId: 0,
                prompt: 'Repair Reason',
                value: '',
            });
        }
        if (reqFields.length > 0) {
            setHasRequiredFields(true);
            st.push({ 
                title: 'Required', 
                description: 'Required information',
                fields: reqFields.map(f => {
                    return {
                        ...f,
                    }
                }),
                completed: false,
                valid: false,
            });
        }
    
        for (let i = 0; i < quantity; i++) {
            st.push({ 
                title: `Repair #${i + 1}`, 
                description: 'Tell us about this device',
                fields: itemFields.map(f => {
                    return {
                        ...f,
                        value: '',
                    };
                }),
                isUrgency: false,
                repairReason: '',
                completed: false,
                valid: false,
            });
        }

        setSteps(st);
    }, []);

    const onSelectedStep = (index) => {
        if (index >= steps.length) {
            let cartItems = [];
            if (hasRequiredFields) {
                // Has required fields
                saveAssetInformationToStorage(steps[0].fields);
                for (let i = 1; i <= quantity; i++) {
                    let itemFields = [...steps[0].fields, ...steps[i].fields];
                    cartItems.push(getCartItem(itemFields, steps[i].isUrgency));
                }
            } else {
                for (let i = 0; i < quantity; i++) {
                    cartItems.push(getCartItem(steps[i].fields, steps[i].isUrgency));
                }
            }
            saveCartItems(cartItems);
        } else {
            setCurrentStepIndex(index);
            setValidCurrentStep(steps[index].valid);
        }
    };

    const onValidateCurrentStep = (valid, completed, index) => {
        if (index === currentStepIndex)
            setValidCurrentStep(valid);
        let arr = steps.slice(); // copy the array
        arr[index].valid = valid;
        arr[index].completed = completed;
        setSteps(arr);
    };

    const onFieldUpdate = (fields) => {
        let arr = steps.slice(); // copy the array
        arr[currentStepIndex].fields = fields;
        setSteps(arr);
    };

    const onUrgencyChanged = (value) => {
        let arr = steps.slice(); // copy the array
        arr[currentStepIndex].isUrgency = value;
        setSteps(arr);
    };

    const onRepairReasonChanged = (value) => {
        let arr = steps.slice(); // copy the array
        arr[currentStepIndex].repairReason = value;
        let fields = arr[currentStepIndex].fields.slice();
        let repReason = fields.find(f => f.fieldUid === '77777777-7777-7777-7777-777777777777');
        if (repReason) {
            repReason.value = value;
        }
        arr[currentStepIndex].fields = fields;
        setSteps(arr);
    };

    const saveCartItems = (cartItems) => {
        let requests = [];
        for (let i = 0; i < cartItems.length; i++) {
            dataLayer.push({
                event: 'brAddToCart',
                brProductId: product.id,
                brProductSku: product.id,
            }, {
                event: 'addToCart',
                ecommerce: {
                    add: {
                        products: [{
                            name: product.partNumber,
                            id: product.id,
                            price: selectedOption.price,
                            brand: product.manufacturer,
                            category: product.modalityId,
                            variant: `${getPartCondition(selectedOption.lineItemCondition)} -  ${getPurchaseChoice(selectedOption.purchaseChoice)}`,
                            quantity: 1,
                            AddLoaner: hasLoanerAdded,
                        }],
                        id_ins: cartItems[i].Id_ins,
                    },
                },
            });

            requests[i] = axios.post('/ShoppingService/api/v1/cart/add', cartItems[i]);
        }

        setSaving(true);
        axios.all(requests)
            .then(axios.spread((...responses) => {
                setShowAddToCartConfirmation(true);
                dispatch(loadUserCart());
                setSaving(false);
            }))
            .catch(errors => {
                setSaving(false);
            });
    };

    const getCartItem = (customFields, isUrgency) => {
        const partImage = product.images && product.images[0].image177;        
        const storageData = sessionStorage.fields ? JSON.parse(sessionStorage.fields) : [];
        let note = storageData.find(f => f.fieldUid === NOTE_KEY);
        const NOTE_KEY = '99999999-9999-9999-9999-999999999999';
        if (customFields.length === 0 && note !== null) {
            customFields.push(note);
        }
        
        const fieldValues = customFields || [];
        const id_ins = getCookie('id_ins');
        let cartItem = {
            UserId: user.info.contactId,
            CompanyId: user.facility.facilityId,
            GroupId: user.facility.groupId,
            RequesterId: user.info.contactId,
            PhoneNumber: user.facility.phoneNumber,
            VendorId: (selectedOption && selectedOption.vendorId) || 0,
            ConditionId: (selectedOption && selectedOption.lineItemCondition) || 0,
            purchaseChoiceId: (selectedOption && selectedOption.purchaseChoice) || 0,
            ResearchRedisId: product.researchRedisId,
            Quantity: 1,
            ProductId: product.id,
            FakeRefurb: selectedOption.fakeRefurb,
            OemId: product.manufacturerId,
            partNumber: product.displayPartNumber,
            PartNumberStripped: product.partNumber,
            description: selectedOption.description,
            ImagePath: partImage,
            WarrantyId: (selectedOption && selectedOption.lineItemWarranty) || 0,
            Fields: fieldValues && fieldValues.filter(x => !x.formularyField) || [],
            IsQuote: isQuote,
            IsProStockItem: (selectedOption && selectedOption.isProStockItem) || false,
            UrgencyId: isUrgency,
            CustomerOrderKey: sessionStorage.wo_id ? sessionStorage.wo_id : '',
            FormularyFields: [],
            Id_ins: id_ins,
            ContractProId: selectedOption && selectedOption.isContractProOption ? selectedOption.formularySetting.contractId : '',
            OemItemNumber: selectedOption.oemItemNumber,
            AddLoaner: hasLoanerAdded,
            ContractValidated: selectedOption && selectedOption.formularySetting ? !selectedOption.formularySetting.hasUnvalidatedContract : false,
            VariantId: selectedOption.variantId,
        };

        if (cartItem.ImagePath === 'https://assets.partsfinder.com/image/') cartItem.ImagePath = '';

        return cartItem;
    };

    const handleAddToCartConfirm = () => {
        history.push(isQuote ? '/quotes' : '/cart');
    };

    const handleAddToCartCancel = () => {
        history.goBack();
    };

    const focusOnContinue = () => {
        const continueButton = document.querySelector(
            `button[id='btnContinue']`
        );
        // If found, focus the next field
        if (continueButton !== null) {
            continueButton.focus();
        }
    };

    return (
        <RepairWizardContainer>
            <RepairWizardContainerRow className="row" >
                <RepairWizardContainerCol className="col-xs-12">
                    <img src={'/images/preview-full-icn_repair.png'} style={{width: '40px'}} />
                </RepairWizardContainerCol>
            </RepairWizardContainerRow>
            <RepairWizardContainerRow className="row">
                <RepairWizardContainerCol className="col-xs-12">
                    <Title>Please tell us about your repairs</Title>
                </RepairWizardContainerCol>
            </RepairWizardContainerRow>
            <RepairWizardContainerRow className="row">
                <RepairWizardContainerCol className="col-xs-12">
                    <StepsCarousel 
                    steps={steps} 
                    product={product} 
                    currentStep={currentStepIndex} 
                    hasRequiredFields={hasRequiredFields} 
                    onSetSelectedStep={onSelectedStep} />
                </RepairWizardContainerCol>
            </RepairWizardContainerRow>
            <RepairWizardContainerRow className="row">
                <RepairWizardFieldsContainerCol 
                    isRequiredRow={hasRequiredFields && currentStepIndex === 0} 
                    className="col-xs-12" rows={steps && steps[currentStepIndex] && steps[currentStepIndex].fields ? steps[currentStepIndex].fields.length : 1}>
                    {hasRequiredFields && <RequiredProperties key={'requiredProps'} myIndex={0} autoFocus={true} currentStepIndex={currentStepIndex} step={steps[0]} onValidateCurrentStep={onValidateCurrentStep} onFieldUpdate={onFieldUpdate} focusOnContinue={focusOnContinue} />}
                    {steps ? 
                        steps.map((s, i) => {
                            if ((hasRequiredFields && i !== 0) || !hasRequiredFields) {
                                return (
                                    <WizardStep key={`step_${i}`} autoFocus={true} steps={steps} step={s} myIndex={i} currentStepIndex={currentStepIndex} hasRequiredFields={hasRequiredFields} onValidateCurrentStep={onValidateCurrentStep} onFieldUpdate={onFieldUpdate} onUrgencyChanged={onUrgencyChanged} onRepairReasonChanged={onRepairReasonChanged} focusOnContinue={focusOnContinue} />
                                )
                            } else {
                                return null;    
                            }
                        }) 
                        : null
                    }
                </RepairWizardFieldsContainerCol>
            </RepairWizardContainerRow>
            <Footer>
                <MediaQuery maxWidth={theme.screenSMMax}>
                    <Button id="btnContinue" disabled={!validCurrentStep} onClick={(e) => onSelectedStep(currentStepIndex + 1, e)} tabIndex={0} className="button button-primary next-button" loading={saving}>{currentStepIndex === steps.length - 1 ? 'Finish' : 'Continue'}</Button>
                    <OrRow><span>OR</span></OrRow>
                    <CancelLinkRow>
                        <CancelLink className={saving ? 'disabled' : ''} onClick={(e) => setShowCancelConfirmation(true)} tabIndex="1">Cancel</CancelLink>
                    </CancelLinkRow>
                </MediaQuery>
                <MediaQuery minWidth={theme.screenMDMin}>
                    <CancelLink className={saving ? 'disabled' : ''} onClick={(e) => setShowCancelConfirmation(true)} tabIndex="1">Cancel</CancelLink>
                    <Button id="btnContinue" disabled={!validCurrentStep} onClick={(e) => onSelectedStep(currentStepIndex + 1, e)} tabIndex={0} className="button button-primary next-button" loading={saving}>{currentStepIndex === steps.length - 1 ? 'Finish' : 'Continue'}</Button>
                </MediaQuery>
            </Footer>
            {showCancelConfirmation ? 
                <PopupAdmin confirmText="Yes"
                    cancelText="No"
                    show={true}
                    hideButtons={false}
                    onCancel={() => setShowCancelConfirmation(false)}
                    onConfirm={(e) => history.goBack()}
                    className="confirm-item-delete-dialog">
                    <div style={{fontSize: '16px'}}>Are you sure?</div>
                </PopupAdmin> : null}
            {showAddToCartConfirmation && <AddToCartConfirmation show={true} product={product} hasOnSiteService={false} isQuote={isQuote} quantity={quantity} hasLoanerAdded={hasLoanerAdded} option={selectedOption} confirmButtonText={isQuote ? 'VIEW QUOTE' : 'VIEW CART'} onConfirm={handleAddToCartConfirm} onCancel={handleAddToCartCancel} />}
        </RepairWizardContainer>
    );
};

RepairWizard.propTypes = {
};

export default RepairWizard;
