import React, {useState, useEffect} from 'react';
import * as PropTypes from 'prop-types';
import {CSSTransition} from 'react-transition-group';
import {TextField} from '@partssourceinc/react-ui-core';
import styled from 'styled-components';
import _ from 'lodash';

import './../../less/repairWizard.less'

const FieldsContainer = styled.div`
    position: absolute;
    left: calc(50% - (280px / 2));
    margin: auto;
    max-width: 280px;
    margin-top: 20px;
    text-align: center;
    @media (min-width: 820px) {
        width: 50%;
    }
`;

const Title = styled.h3`
    font-weight: 600;
`;

const SectionContainer = styled.div`
    width: 280px;
    max-width: 100%;
    text-align: left;
`;

const WizardStep = (props) => {
    const {steps, step, onFieldUpdate, autoFocus, onValidateCurrentStep, myIndex, currentStepIndex, hasRequiredFields, onRepairReasonChanged, focusOnContinue} = props;
    const [showErrors, setShowErrors] = useState(false);
    const [repairReason, setRepairReason] = useState('');
    const [errors, setErrors] = useState({});
    const [fields, setFields] = useState([]);
    const [dirtyFields, setDirtyFields] = useState([]);
    const [totalDevices, setTotalDevices] = useState(0);
    const [currentDevice, setCurrentDevice] = useState(0);
    const [show, setShow] = useState(false);
    const [enableMoveNext, setEnableMoveNext] = useState(false);
    const [invalidFields, setInvalidFields] = useState([]);

    useEffect(() => {
        if (step && step.fields) {
            setFields(step.fields);
            setDirtyFields([]);
            let completed = checkCompleted(step.fields, step.repairReason);
            let valid = validateForm(step.fields, step.repairReason, !completed, []);

            if (onValidateCurrentStep)
                onValidateCurrentStep(valid, completed, myIndex);

            // Display or hide extra info for repair reason.
            setRepairReason(step.repairReason);

            if (hasRequiredFields) {
                setTotalDevices(steps.length - 1);
                
            } else {
                setTotalDevices(steps.length);
            }
        }
    }, [step]);

    useEffect(() => {
        setEnableMoveNext(false);
        setShow(myIndex === currentStepIndex);
        if (hasRequiredFields) {
            setCurrentDevice(currentStepIndex);
        } else {
            setCurrentDevice(currentStepIndex + 1);
        }
        setTimeout(() => {
            setEnableMoveNext(true);
        }, 500);
    }, [currentStepIndex]);

    const updateState = (event, data) => {
        let arr = fields.slice(); // copy the array
        arr.filter(x => x.fieldUid === data.id)[0].value = data.value;
        setFields(arr);

        let dirtyArr = dirtyFields.slice();
        if (!dirtyArr.find(d => d === data.id)) {
            dirtyArr.push(data.id);
        }
        setDirtyFields(dirtyArr);

        let valid = validateForm(arr, repairReason, true, dirtyArr);
        let completed = checkCompleted(arr, repairReason);

        if (onValidateCurrentStep)
            onValidateCurrentStep(valid, completed, myIndex);

        if (onFieldUpdate) 
            onFieldUpdate(arr);
    };
    
    const updateRepairReason = (rr) => {
        setRepairReason(rr);

        if (onRepairReasonChanged)
            onRepairReasonChanged(rr);

        let dirtyArr = dirtyFields.slice();
        if (!dirtyArr.find(d => d === 'repairReason')) {
            dirtyArr.push('repairReason');
        }
        setDirtyFields(dirtyArr);
        
        let valid = validateForm(fields, rr, true, dirtyArr);
        let completed = checkCompleted(fields, rr);

        if (onValidateCurrentStep)
            onValidateCurrentStep(valid, completed, myIndex);
    };

    const validateForm = (flds, repReason, onlyDirty, dirtyArr) => {
        let errs = {};
        let invalidFields = {};
        if (flds && flds.length) {
            // Validate required fields - so far only serial number
            // Exclude repair reason as it has a particular treatment
            const fds = flds.filter(f => f.fieldUid !== '77777777-7777-7777-7777-777777777777');
            for (let i = 0; i < fds.length; i++) {
                if (fds[i].isRequired && 
                    ((!onlyDirty && (!fds[i].value || fds[i].value === '')) || (onlyDirty && dirtyArr.find(d => d === fds[i].fieldUid) && (!fds[i].value || fds[i].value === '')))
                ) {
                    errs[fds[i].fieldUid] = `${fds[i].prompt} is required`;
                }

                // Check if serial # is already used in another step
                if (fds[i].fieldUid === '44444444-4444-4444-4444-444444444444' && fds[i].value) {
                    let serialUsed = steps.some((s, idx) => idx !== currentStepIndex && s.fields.some(f => f.fieldUid === '44444444-4444-4444-4444-444444444444' && f.value === fds[i].value));
                    if (serialUsed)
                        errs[fds[i].fieldUid] = `${fds[i].prompt} is already used`;
                    if (fds[i].formatRegex && !new RegExp(`^${fds[i].formatRegex}$`, '').test(fds[i].value))
                        errs[fds[i].fieldUid] = fds[i].errorMsg; 
                }
            }
            invalidFields = (fds || []).filter(x => x.value && x.formatRegex && !new RegExp(`^${x.formatRegex}$`, '').test(x.value));
            let selectedRepairReasonError = false;

            let repReasonField = flds.find(f => f.fieldUid === '77777777-7777-7777-7777-777777777777');
            // Check repair reason
            if (repReasonField) {
                if (_.isEmpty(repReason)) {
                    selectedRepairReasonError = true;
                    if (!onlyDirty || (onlyDirty && dirtyArr.find(d => d === 'repairReason'))) 
                        errs.repairReason = 'Repair reason is required';
                }
            } 

            // See if errors are displayed
            let anyError = fds.some(f => f.isRequired && _.isEmpty(f.value)) || !_.isEmpty(errs) || selectedRepairReasonError || !_.isEmpty(invalidFields);
            setErrors(errs);
            setInvalidFields(invalidFields);
            setShowErrors(anyError);
            return !anyError;
        } else {
            setErrors(errs);
            setShowErrors(false);
            return true;
        }
    }

    const checkCompleted = (flds, repReason) => {
        let repReasonField = flds.find(f => f.fieldUid === '77777777-7777-7777-7777-777777777777');
        // Check that all required fields have values
        if (flds && flds.length) {
            const fieldsToCheck = flds.filter(f => f.fieldUid !== '77777777-7777-7777-7777-777777777777');
            if (fieldsToCheck.some(f => f.isRequired && !f.value))
                return false;
            else {
                if (repReasonField) {
                    if (_.isEmpty(repReason)) {
                        return false;
                    }
                } else {
                    return true;
                }
            }
        } else {
            return true;
        }
    }

    const moveToNextEvent = (event, id) => {
        if (event.keyCode === 13 && enableMoveNext) {
            moveToId(id);  
            event.stopPropagation();
        }
    };

    const moveToContinueEvent = (event) => {
        if (event.keyCode === 13 && enableMoveNext) {
            focusOnContinue();  
            event.stopPropagation();
        }
    }

    const moveToId = (id) => {
        const item = document.querySelector(
            `input[id='${id}']`
        );
        // If found, focus the next field
        if (item !== null) {
            item.focus();
        }
    }
    
    const renderSection = () => {
        const serialFieldUuid = '44444444-4444-4444-4444-444444444444';
        const errorCodeFieldUuid = '77777777-7777-7777-7777-777777777777';
        const serialField = fields ? fields.find(f => f.fieldUid === serialFieldUuid) : null;
        const errorCodeField = fields ? fields.find(f => f.fieldUid === errorCodeFieldUuid) : null;

        return (
            <SectionContainer>
                {serialField && <TextField
                    className="txbFields"
                    text={serialField.value}
                    autoFocus = {autoFocus}
                    id={`${serialField.fieldUid}`}
                    tabIndex={0}
                    label={serialField.prompt}
                    placeholder={serialField.placeholder || `Enter ${serialField.prompt}`}
                    pattern = {serialField.formatRegex}
                    errorMessage={errors[serialField.fieldUid] || serialField.errorMsg} 
                    style={{textAlign: 'left'}}
                    maxLength={32}
                    showErrorMessage={showErrors && errors[serialField.fieldUid]}
                    onChange={(e, d) => updateState(e, d)}
                    onKeyUp={(e) => errorCodeField ? moveToNextEvent(e, errorCodeField.fieldUid) : moveToContinueEvent(e)}
                />}
                {errorCodeField && <TextField
                    className="txbFields"
                    text={repairReason}
                    id={`${errorCodeField.fieldUid}`}
                    tabIndex={0}
                    label={errorCodeField.prompt}
                    placeholder={errorCodeField.placeholder || `Enter ${errorCodeField.prompt}`}
                    showErrorMessage={showErrors && errors.repairReason}
                    errorMessage={errors.repairReason}
                    maxLength={150}
                    style={{marginTop: 20, textAlign: 'left'}}
                    onChange={(e, d) => updateRepairReason(d.value)}
                    onKeyUp={(e) => moveToContinueEvent(e)}
                />}              
            </SectionContainer>
        );
    };

    return (
        <CSSTransition
            in={show}
            timeout={500}
            classNames="display"
            unmountOnExit={true}
        >
            <FieldsContainer>
                <Title>Device {currentDevice} of {totalDevices}</Title>
                {renderSection()}
            </FieldsContainer>
        </CSSTransition>);
};

WizardStep.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.object).isRequired, 
    step: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        fields: PropTypes.arrayOf(PropTypes.object).isRequired,
        isUrgency: PropTypes.bool,
        repairReason: PropTypes.string,
        completed: PropTypes.bool.isRequired,
        valid: PropTypes.bool.isRequired,
    }).isRequired, 
    onFieldUpdate: PropTypes.func, 
    autoFocus: PropTypes.bool, 
    onValidateCurrentStep: PropTypes.func, 
    myIndex: PropTypes.number.isRequired, 
    currentStepIndex: PropTypes.number.isRequired, 
    hasRequiredFields: PropTypes.bool.isRequired, 
    onUrgencyChanged: PropTypes.func, 
    onRepairReasonChanged: PropTypes.func, 
    focusOnContinue: PropTypes.func,
};

export default WizardStep;
